import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import {
    CtButtonConfiguration,
    CtFormContainerComponent,
    CtModelConfiguration,
    CtModelRouteData, CtModelService,
    CtModelType, MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { Subscription } from "rxjs";
import { ContractTypeListElementDTO } from "../../../../core/controller/contract-types.controller";
import { RoleService } from "../../../../core/lib/role.service";
import { ContractExtraFieldConfiguration } from "./contract-extra-field.configuration";

const SPECIFICCONTRACT_ROUTEDATA = (): CtModelRouteData => CtModelRouteData
    .create()
    .setController("SpecificContract");

@Component({
    selector: 'app-contract-extra-field',
    templateUrl: './contract-extra-field.component.html',
    styleUrls: ['./contract-extra-field.component.scss']
})
export class ContractExtraFieldComponent {

    @ViewChild('FormContainer') formContainer: CtFormContainerComponent<any> | null = null;

    @Output() close: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateAmount: EventEmitter<number | null> = new EventEmitter<number | null>();

    specificContractConfiguration: ContractExtraFieldConfiguration | null = null;
    modelConfiguration: CtModelConfiguration<ContractExtraFieldComponent> | null = null;
    currentValue: any | null = null;
    cancelButton: CtButtonConfiguration = CtButtonConfiguration
        .create()
        .setClass('m-r-10')
        .setLabel("Chiudi")
        .setAction(() => this.close.emit())
        .setMatherialOptions(MAT_RAISED_WARN);

    subscription: Subscription | null = null;
    genericSubscription: Subscription | null | undefined = null;

    viewModel = {
        isTech: false
    }

    constructor(
        private formBuilder: FormBuilder,
        private _webapi: CtWebapiService,
        private modelService: CtModelService<any>,
        private roleService: RoleService,
        private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {

        this.setupPermissions()
            .then(() => this.cdr.detectChanges())

    }

    private async setupPermissions() {

        this.viewModel.isTech = await this.roleService.is.tech();

    }

    setup(configuration: ContractExtraFieldConfiguration | null) {

        this.modelConfiguration = null;

        this.specificContractConfiguration = configuration;

        setTimeout(() => {

            if (this.specificContractConfiguration) {

                const routeData: CtModelRouteData = SPECIFICCONTRACT_ROUTEDATA()
                    .setModelType(CtModelType.FORM);

                const request: DataRequest = DataRequest.create()
                    .setController([routeData.controller!])
                    .setAction('FieldsByContractType')
                    .setQueryParams({
                        hideSpinner: true
                    })
                    .setMethod(MethodEnum.POST)
                    .setContentType('application/json-patch+json')
                    .setBody(this.specificContractConfiguration.contractType?.Code);

                const modelConfiguration: CtModelConfiguration<any> = CtModelConfiguration.create<ContractExtraFieldComponent>()
                    .setRouteData(routeData)
                    .setGetFieldsDataRequest(request)
                    .setDefaultActionsEnabled(false)

                this.modelService
                    .getFields(modelConfiguration)
                    .subscribe({
                        next: (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

                            modelConfiguration
                                ?.setupCTModelConfig(response.Result)

                            this.modelConfiguration = modelConfiguration;

                            setTimeout(() => {

                                this.subscribeDynamicItalianCurrency();

                                if (this.currentValue) this.formContainer?.form?.patchValue(this.currentValue);

                                if (this.specificContractConfiguration?.disabled || this.viewModel.isTech) { // in preview deve essere disabilitato il form

                                    this.formContainer?.form?.disable();

                                }

                                this.subscribeToAutoIncrementalAmount(configuration?.contractType ?? null);

                            })

                        },
                        error: () => this.modelService.onError()
                    })

            }

            this.cdr.detectChanges();

        })

    }

    subscribeDynamicItalianCurrency(){
        this.genericSubscription = this.formContainer?.form?.valueChanges?.subscribe((value) => {
            Object.keys(value).forEach((key) => {
                if (key.includes('Price') || key.includes('Amount')) {
                    if(value[key].toString().includes('.')) {
                        if(key.includes('CopyBW') || key.includes('CopyColor')) {
                            value[key] = Number(value[key]).toLocaleString('it-IT', { minimumFractionDigits: 4, maximumFractionDigits: 4 });
                        }else{
                            value[key] = Number(value[key]).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                        }
                        this.formContainer?.form?.patchValue(value);
                    }
                }
            });
        });

    }

    setFormValue(value: any) {

        this.currentValue = value;

    }

    subscribeToAutoIncrementalAmount(contractType: ContractTypeListElementDTO | null) {

        this.resetAutoIncrementalAmountValueChangeSubscription();

        if (contractType?.AutoIncrementalAmount) {
            const incrementalField = 'FinalPrice';
            const objectKey = 'lstSoftwareSubscriptionRowDTO';

            this.subscription = this.formContainer
                ?.form
                ?.valueChanges
                ?.subscribe((value) => {

                    const list = value[objectKey];
                    let amount = 0;
                    if (Array.isArray(list)) {
                        list.forEach((oggetto) => {
                                if(oggetto.InstancePrice.toString().includes(',')) {
                                    oggetto.InstancePrice = oggetto.InstancePrice.toString().replaceAll('.', '');
                                    oggetto.InstancePrice = oggetto.InstancePrice.toString().replaceAll(',', '.');
                                }
                                oggetto[incrementalField] = (Number(oggetto.InstanceNumber ?? 0) * Number(Number(oggetto.InstancePrice ?? 0).toFixed(2))).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                
                                oggetto.InstancePrice = Number(oggetto.InstancePrice).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                
                                if(oggetto[incrementalField].toString().includes(',')) {
                                    oggetto[incrementalField] = oggetto[incrementalField].toString().replaceAll('.', '');
                                    oggetto[incrementalField] = oggetto[incrementalField].toString().replaceAll(',', '.');
                                }

                                const increment = oggetto[incrementalField];

                                if (increment !== undefined) {
                                    const valueAsNumber = Number(increment);

                                    if (!isNaN(valueAsNumber)) amount += valueAsNumber;
                                }

                                oggetto[incrementalField] = Number(oggetto[incrementalField]).toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2});
                            });
                    }
                    this.updateAmount.emit(amount);
                }) ?? null;
        }
    }

    ngOnDestroy() {

        this.resetAutoIncrementalAmountValueChangeSubscription();

    }

    private resetAutoIncrementalAmountValueChangeSubscription() {
        Object.keys(this).forEach((key) => {
            let value = this[key as keyof typeof this];
            if (value instanceof Subscription) {
                value.unsubscribe();
            }
        });

        // this.subscription?.unsubscribe();
        // this.subscription = null;

    }

}
